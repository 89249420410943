import React from 'react'
import './layout.css';
import { Grommet, Grid, Box } from 'grommet'
import { deepMerge } from 'grommet/utils'
import Sidebar from './sidebar.js'


const Layout = ({ children }) => {
  return <Grommet
    full={true}
    theme={theme}
  >
    <Grid
      rows={['flex']}
      columns={['60px', 'auto']}
      gap="large"
      margin="0px"
      fill
      areas={[
        { name: 'sidebar', start: [0, 0], end: [0, 0] },
        { name: 'main', start: [1, 0], end: [1, 0] },
      ]}>
      <Box gridArea="main">{ children }</Box>
    </Grid>
    <Sidebar />
    <div id="websiteFrameTop" className="websiteFrame slide-in-left"></div>
    <div id="websiteFrameRight" className="websiteFrame slide-in-top"></div>
    <div id="websiteFrameBottom" className="websiteFrame slide-in-right"></div>
  </Grommet>
}
const theme = deepMerge(Grommet, {
  global: {
    colors: {
      "brand": "#F7323F",
      "light-carmine-pink": "#DD6868",
      "pink-sherbet": "#F7A7A5"
    }
  }
});

export default Layout;
